<template>
  <div class="ourservice-btn mybutton"  @click="toRouter('/service')">
    <img  :src="require('/src/assets/img/right-arrow.png')" /><span
      >Our Service</span
    >
  </div>
</template>

<script>
export default {
  methods: {
    sel(e) {
      this.$emit("change", e);
    },
     toRouter(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.ourservice-btn {

  opacity: 0.7;
  background: linear-gradient(180deg, #6d7278, #050505);
  border-radius: 41.25px;
  position: fixed;
  top: 9.46vh;
  right: 2.19vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 35px;
  img {
    height: 24px;
    margin-right: 22px;
    transform: rotate(180deg);
  }
  span {
    font-size: 21px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 30px;
    letter-spacing: 1.31px;
  }
}
</style>
