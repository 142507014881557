<template>
  <div class="tabs">
    <div class="tab" v-for="(item, index) of list" :key="index">
      <div class="title mybutton" :class="{ sel: value === index }" @click="sel(index)">
        {{ item.title }}
        <div class="hr" />
        <div class="height-light" v-if="value === index"></div>
      </div>
      <div class="detail" v-if="value === index && item.context">
        <img :src="require('/src/assets/img/tag.png')" /><span
          ><div
            v-if="item.header"
            style="font-size: 2.08vh; text-align: center; margin-bottom: 14px"
          >
            {{ item.header }}
          </div>
          <div>
            {{ item.context }}
          </div></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    list: Array,
    value: Number,
  },
  methods: {
    sel(e) {
      this.$emit("change", e);
    },
  },
};
</script>

<style lang="less" scoped>
.tabs {
  font-size: 2.5vh;
  font-family: Helvetica, Helvetica-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 2.99vh;
  letter-spacing: 1.69px;

  margin-left: 2.604vw;
  width: 19.43vw;
  .hr {
    border: 1px solid #ffffff;
    margin-top: 1vh;
  }
  .sel .hr {
    border: 0px;
    border-top: 3px solid #e02020;
    height: 18.75px;
    background: rgba(249, 0, 0, 0.3);
  }
  .tab {
    margin-bottom: 20px;
    margin-top: 14px;
  }
  .detail {
    display: flex;
    padding: 2.31vh 1.3vw;
    font-size: 1.67vh;
    line-height: 2.01vh;
    letter-spacing: 0.104vh;
    align-items: center;
    background: #00000065;
    > img {
      height: 2vh;
      margin-right: 19px;
    }
  }
  .sel {
    font-size: 2.78vh;
    line-height: 3.33vh;
    letter-spacing: 1.88px;
    text-align: center;
  }
}
</style>
