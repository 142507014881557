<template>
  <div class="ev-system">
    <div class="top">
      <div style="transform: translate(-2vw, -2vh)">
        <div>
          <span style="color: #f60909">One-Step </span>intelligent parking
        </div>
        <div>solution provider</div>
        <div style="font-size: 1.8vh">
          Solve the pain points of parking lot operators,managers and drivers
        </div>
      </div>
    </div>
    <div class="option">
      <div class="block">
        <img
          :src="require('/src/assets/img/service/operating-platform.png')"
        />Operating Platform
      </div>
      <div class="block">
        <img
          :src="require('/src/assets/img/service/APP-applet.png')"
        />APP/Applet
      </div>
      <div class="block">
        <img
          :src="require('/src/assets/img/service/charge-management.png')"
        />Charge Management
      </div>
      <div class="block">
        <img
          :src="require('/src/assets/img/service/parking-navigation.png')"
        />Parking Navigation system
      </div>
      <div class="block">
        <img :src="require('/src/assets/img/service/EVC-system.png')" />EVC
        System
      </div>
    </div>
    <div class="bottom">
      <div class="block" style="width:26vw">
        <img
          :src="
            require('/src/assets/img/service/charging-service-management.png')
          "
          style="height: 26.12vh"
        />Charging Service management platform
      </div>
      <div style="flex:1;margin-left: 2vw">
        <div class="text">- Safe charging</div>
        <div class="text">- Adjustable charging power</div>
        <div class="text">- Remote monitoring and upgrading</div>
        <div class="text">
          - Meet the standard communication protocol of China Electronics Union
        </div>
        <div class="text">- Support multi tier and multi tenant</div>
      </div>
      <div class="block" style="margin-left: 2vw;">
        <img
          :src="require('/src/assets/img/service/charging-user-APP.png')"
          style="height: 26.12vh"
        />Charging User APP
      </div>
      <div style="flex:1">
        <div class="text">
          - Online payment and self-service payment machine are suitable for
          mixed use in private and public places
        </div>
        <div class="text">
          - New energy vehicles compatible with different interfaces
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.ev-system {
  font-size: 2.5vh;
  font-family: Helvetica, Helvetica-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 2.99vh;
  letter-spacing: 1.69px;
  padding-left: 3.02vw;

  .block {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .top {
    height: 23.98vh;
    background: #00000065;
    border: 0.75px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5vh;
    line-height: 5.2vh;
    margin-top: 25px;
  }
  .option {
    margin-top: -5.1vh;
    width: 100%;
    display: flex;
    img {
      height: 10.37vh;
      margin-bottom: 2.22vh;
    }
  }
  .bottom {
    position: fixed;
    top: 56.86vh;
    left: 0px;
    padding: 0px 25px;
    display: flex;
    .block{
      flex: unset;
    }
    .text {
      margin-bottom: 3vh;
    }
  }
}
</style>
