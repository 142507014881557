<template>
  <div class="st-sid">
    <div class="left">
      <div class="block">
        <img
          style="height: 25.56vh; margin-left: 3vw"
          :src="require('/src/assets/img/service/charging-pile1.png')"
        />Single Phase AC Charging Pile
      </div>
      <div class="block">
        <img
          style="height: 38.43vh"
          :src="require('/src/assets/img/service/charging-pile2.png')"
        />Floor type single/double gun DC charging pile
      </div>
    </div>
    <div class="right">
      <div class="row" style="margin-bottom: 7.96vh;margin-top: 2.96vh">
        - Rated Voltage: 220V AC、Single Phase、50HZ <br />- Input Current：
        8A—32A（maximum） <br />- Communication： GPRS、Wi-Fi、Ethernet <br />- Levels
        of Protection： IP54 <br />- Size： 400mm*200mm*100mm
      </div>
      <div class="row">
        - Rated Power: 60 kW、90kW 、120kW、180kW <br />- Voltage Range:
        200-500V/200-750V <br />- Current Range: 0-144A/0-96A \ 0-216A/0-144A
        0-250A/0-192A \ 0-250A/0-250A <br />- Power distribution: uniform
        charging, round charging, intelligent distribution <br />- Charging
        Efficiency: ≥94.5% <br />- Reliability：MTBF≥100000Hrs
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mobi",
};
</script>

<style lang="less" scoped>
.st-sid {
  display: flex;
  font-size: 2.5vh;
  font-family: Helvetica, Helvetica-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 3.47vh;
  letter-spacing: 1.69px;
  height: 100%;
  width: 100%;
  .left {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: -4vw;
    .block {
      display: flex;
      flex-direction: column;
      align-items: center;
      > img {
        margin-bottom: 5px;
      }
    }
  }
  .right {
    flex: 1;
    height: 100%;
    margin-left: 2.25vw;
    .row {
      background: #1e2c1565;
      border: 3px solid #827b7b;
      border-right: 0px;
      display: flex;
      align-items: center;
      padding: 2.59vh 1.51vw 4.59vh;
    }
  }
}
</style>
